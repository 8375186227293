<template>
    <div>
        <div class="text-right">
            <button
                @click="handleDownload()"
                class="
                    px-4
                    py-2
                    mb-4
                    bg-gray-50
                    border
                    rounded-md
                    text-balck
                    flex
                "
            >
                <img src="../../../assets/excel.png" alt="" />
                <span class="mx-3">Download</span>
            </button>
        </div>
        <!-- {{ $route.params.result }} -->

        <div class="flex text-lg mb-4">
            <h1 class="pr-4 border-r uppercase font-bold">
                {{ $route.params.reportOn.replace(/_/g, ' ') }}..
            </h1>
            <h1 class="px-4 border-r">
                From:
                <span class="font-bold">{{
                    $route.params.from | moment('ddd, MMM Do YYYY')
                }}</span>
            </h1>
            <h1 class="px-4">
                To:
                <span class="font-bold">{{
                    $route.params.to | moment('ddd, MMM Do YYYY')
                }}</span>
            </h1>
        </div>
        <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
                <tr>
                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Date
                    </th>
                    <th
                        v-if="$route.params.agent != 'all'"
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Agent
                    </th>
                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-center text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Total Deposits
                    </th>

                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Total Transactions
                    </th>

                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Total Sales
                    </th>

                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Agent Commission
                    </th>
                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Chapman Commission
                    </th>
                    <th
                        scope="col"
                        class="
                            px-6
                            py-3
                            text-left text-xs
                            font-medium
                            text-gray-500
                            uppercase
                            tracking-wider
                        "
                    >
                        Total Commission
                    </th>
                </tr>
            </thead>

            <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="(transaction, i) in data" :key="i">
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="">
                                <div class="text-sm font-medium text-gray-900">
                                    {{ transaction.date }}
                                </div>
                            </div>
                        </div>
                    </td>

                    <td
                        v-if="transaction.agent"
                        class="px-6 py-4 whitespace-nowrap text-center"
                    >
                        <div class="text-sm text-gray-900">
                            <b>{{ transaction.agent }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            GMD
                            <b>{{ formatPrice(transaction.total_deposits) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            {{ transaction.total_transactions }}
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD
                            <b>{{ formatPrice(transaction.total_sales) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.agent_commission)
                            }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.chapman_commission)
                            }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD
                            <b>{{
                                formatPrice(transaction.total_commissions)
                            }}</b>
                        </div>
                    </td>
                </tr>

                <tr class="bg-gray-50">
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="flex items-center">
                            <div class="ml-4">
                                <div class="text-sm font-medium text-gray-900">
                                    <b>TOTAL</b>
                                </div>
                            </div>
                        </div>
                    </td>

                    <td
                        v-if="$route.params.agent != 'all'"
                        class="px-6 py-4 whitespace-nowrap"
                    >
                        <div class="text-sm text-gray-900 text-center"></div>
                    </td>
                    <!-- <td class="px-6 py-4 whitespace-nowrap"></td> -->
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900 text-center">
                            GMD <b>{{ formatPrice(totalDeposits) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap text-center">
                        <div class="text-sm text-gray-900">
                            <b>{{ totalTransactions }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(totalSales) }}</b>
                        </div>
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(agentCommission) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(chapmanCommission) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap">
                        <div class="text-sm text-gray-900">
                            GMD <b>{{ formatPrice(totalCommission) }}</b>
                        </div>
                    </td>

                    <td class="px-6 py-4 whitespace-nowrap"></td>
                </tr>

                <!-- More people... -->
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: ['data'],

    data: () => ({
        downloadLoading: false,

        filename: '',
        autoWidth: true,
        bookType: 'xlsx',
    }),

    computed: {
        totalTransactions: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.total_transactions); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        totalSales: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(Number(val.total_sales)); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        totalCommission: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log('keyyyy', key);
                total.push(val.total_commissions); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        agentCommission: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log('keyyyy', key);
                total.push(val.agent_commission); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        chapmanCommission: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log('keyyyy', key);
                total.push(val.chapman_commission); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },

        totalDeposits: function () {
            let total = [];

            Object.entries(this.data).forEach(([key, val]) => {
                console.log(key);
                total.push(val.total_deposits); // the value of the current key.
            });

            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },
    },

    methods: {
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace(',', '.');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },

        getFileName() {
            return (
                this.$route.params.reportOn +
                '-' +
                this.$route.params.from +
                '-' +
                this.$route.params.to
            );
        },

        handleDownload() {
            this.downloadLoading = true;
            import('@/vendor/Export2Excel').then(excel => {
                const tHeader = [
                    'Customer',
                    'Deposits',
                    'Sales',
                    ' Commissions',
                    'Transactions',
                ];
                const filterVal = [
                    'agent',
                    'total_deposits',
                    'total_sales',
                    'total_commissions',
                    'total_transactions',
                ];
                const list = this.data;
                console.log('LIST', list);
                const data = this.formatJson(filterVal, list);
                excel.export_json_to_excel({
                    header: tHeader,
                    data,
                    filename: this.getFileName(),
                    autoWidth: this.autoWidth,
                    bookType: this.bookType,
                });
                this.downloadLoading = false;
            });
        },

        formatJson(filterVal, jsonData) {
            return Object.keys(jsonData).map(v => {
                console.log();
                return filterVal.map(j => {
                    console.log('J', j);
                    console.log();

                    if (j === 'branch') {
                        return v;
                    } else if (j === 'total') {
                        return (
                            Number(jsonData[v]['amount']) +
                            Number(jsonData[v]['commission'])
                        );
                    } else {
                        return jsonData[v][j];
                    }
                });
            });
        },
    },
};
</script>
